<div class="app-upload-image">
    <app-loading-states [loading]="loading"></app-loading-states>
    <image-cropper [imageChangedEvent]="imageChangedEvent"
                   [maintainAspectRatio]="false"
                   [aspectRatio]="1 / 1"
                   backgroundColor="white"
                   format="jpeg"
                   alignImage="left"
                   outputType="both"
                   [imageURL]="imageUrl"
                   (imageCropped)="imageCropped($event)"
                   (imageLoaded)="imageLoaded()"
                   (cropperReady)="cropperReady()"
                   (loadImageFailed)="loadImageFailed()"></image-cropper>
    <ng-container *ngIf="url">
        <img class="img"
             crossorigin="Anonymous"
             [src]="url" />
    </ng-container>
    <div>
        <button class="btn btn-secondary btn-sm"
                type="button"
                (click)="input_file.click()">
            <ng-container *ngIf="!url">
                Загрузить
            </ng-container>
            <ng-container *ngIf="url">
                Заменить
            </ng-container>
        </button>
        <ng-container *ngIf="url && showEditBtn && !nowEdited">
            <button class="btn btn-secondary btn-sm"
                    type="button"
                    (click)="onEdit()">
                Редактировать
            </button>
        </ng-container>
        <ng-container *ngIf="url && showApplyBtn">
            <button class="btn btn-secondary btn-sm"
                    type="button"
                    (click)="onApplyClick()">
                Применить
            </button>
        </ng-container>
        <input #input_file
               class="app-upload-image__file"
               type="file"
               (change)="fileChangeEvent($event)">
        <button class="btn btn-danger btn-sm"
                type="button"
                (click)="onClickDelete();">
            <i class="fa fa-remove"></i>
        </button>
    </div>
</div>
