<ng-container *ngIf="control">
    <label class="m-checkbox">
        <input #checkbox
               id="in_{{id}}"
               type="checkbox"
               autocomplete="off"
               [attr.disabled]="disabled"
               [formControl]="absToFormCtr(control)"
               [class.error]="(control.invalid && control.dirty && control.root.get('submit').value) ||
            (control.pristine && control.invalid && control.root.get('submit').value)"
               (keyup.enter)="evenEnter()" /><span></span>
        {{label}}
    </label>
</ng-container>
