import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading-states',
    templateUrl: './states.component.html',
    styleUrls: ['./states.component.css'],
})
export class StatesComponent implements OnInit {
    @Input() loading: boolean;

    constructor() {}

    ngOnInit() {}
}
