import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailComponent } from './email/email.component';
import { InpitImageComponent } from './image/image.component';
import { ImagePreviewDirective } from './image/img-preview.directive';
import { LoadingModule } from '../loading/loading.module';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import { SelectFindComponent } from './select-find/select-find.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SelectComponent } from './select/select.component';
import { DateComponent } from './date/date.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { InputDateRangeComponent } from './date-range/date-range.component';
import { NumberComponent } from './number/number.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';
import { CoordinatesComponent } from './coordinates/coordinates.component';
import { RadioComponent } from './radio/radio.component';
import { InputDateTimeRangeComponent } from '@shared/modules/input/date-time-range/date-range.component';
import { SelectFindMultipleComponent } from '@shared/modules/input/select-find-multiple/select-find-multiple.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectFindMultipleAddComponent } from '@shared/modules/input/select-find-multiple-add/select-find-multiple-add.component';
import { SelectFindAutocompleteComponent } from '@shared/modules/input/select-find-autocomplete/select-find-autocomplete.component';
import { InputMultipleImageComponent } from '@shared/modules/input/image-multiple/image-multiple.component';
import { InputImageCroppieComponent } from '@shared/modules/input/image-croppie/image-croppie.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MultiSelectComponent } from '@shared/modules/input/multi-select/multi-select.component';
import { SelectFindAutocompleteV2Component } from '@shared/modules/input/select-find-autocomplete-v2/select-find-autocomplete.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        NgSelectModule,
        ImageCropperModule,
    ],
    declarations: [
        InputDateRangeComponent,
        EmailComponent,
        InpitImageComponent,
        ImagePreviewDirective,
        TextComponent,
        TextareaComponent,
        SelectFindComponent,
        CheckboxComponent,
        SelectComponent,
        DateComponent,
        DateTimeComponent,
        NumberComponent,
        WysiwygComponent,
        CoordinatesComponent,
        RadioComponent,
        InputDateTimeRangeComponent,
        SelectFindMultipleComponent,
        SelectFindMultipleAddComponent,
        SelectFindAutocompleteComponent,
        InputMultipleImageComponent,
        InputImageCroppieComponent,
        MultiSelectComponent,
        SelectFindAutocompleteV2Component,
    ],
    exports: [
        InputDateRangeComponent,
        EmailComponent,
        InpitImageComponent,
        TextComponent,
        TextareaComponent,
        SelectFindComponent,
        CheckboxComponent,
        SelectComponent,
        DateComponent,
        DateTimeComponent,
        NumberComponent,
        WysiwygComponent,
        CoordinatesComponent,
        RadioComponent,
        InputDateTimeRangeComponent,
        SelectFindMultipleComponent,
        SelectFindMultipleAddComponent,
        SelectFindAutocompleteComponent,
        InputMultipleImageComponent,
        InputImageCroppieComponent,
        MultiSelectComponent,
        SelectFindAutocompleteV2Component,
    ],
})
export class InputModule {}
