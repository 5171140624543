import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { SelectFindMultipleAdd } from './select-find-multiple-add';
import { forEach, find } from 'lodash';

@Component({
    selector: 'app-input-select-find-multiple-add',
    templateUrl: 'select-find-multiple-add.component.html'
})
export class SelectFindMultipleAddComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormArray;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() options: SelectFindMultipleAdd[];
    @Input() loading: boolean = false;
    @Input() search: boolean = true;
    @Input() clearable: boolean = true;
    @Input() notFoundText: string = 'Не найдено';
    @Input() addTag: any;
    @Input() addTagText: string = '';

    @Output() enter = new EventEmitter<boolean>();
    @Output() change = new EventEmitter<any>();
    @Output() find = new EventEmitter<any>();
    @Output() button = new EventEmitter<any>();

    @ViewChild('select_find_multiple_add') elemInput: ElementRef;

    public value: number = 0;

    public items: { id: number | string, name: string }[] = [];

    public selected;
    public inputValue: string = '';

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
        if (this.control.value) {
            this.selected = this.control.value;
        }

        this.control.valueChanges.subscribe((res) => {
            this.selected = res.reduce((a, v: any) => {
                if (v) {
                    a.push(v);
                }
                return a;
            }, []);
        });

    }


    ngOnDestroy() {
    }

    onChange(arr) {
        this.control.reset();
        this.control.controls = [];
        forEach(arr, (v: any) => {
            this.control.push(new UntypedFormControl(v.id));
            let tmp = [];
            forEach(arr, (v: any) => {
                let findItem = find(this.options, { id: v.id });
                if (!findItem) {
                    tmp.push({ id: v.id, name: v.name });
                }
            });
            this.options = this.options.concat(tmp);
        });
    }

    onSearch(event) {
        this.find.emit(event.term);
    }
}
