import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
} from '@angular/forms';
import { SelectFind } from '@shared/modules/input/select-find/select-find';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';

@Component({
    selector: 'app-input-select-find-multiple',
    templateUrl: 'select-find-multiple.component.html',
    providers: [AbsToFromControlService],
})
export class SelectFindMultipleComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormArray | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() clearable: boolean = true;
    @Input() options: SelectFind[];
    @Input() loading: boolean = false;
    @Input() search: boolean = true;
    @Input() notFoundText: string = 'Не найдено';

    @Output() enter = new EventEmitter<boolean>();
    @Output() change = new EventEmitter<any>();

    @ViewChild('select_find_multiple') elemInput: ElementRef;

    public value: number = 0;

    public items: { id: number | string; name: string }[] = [];

    public selected;

    public absToFormCtr: Function;

    constructor(private _absToForm: AbsToFromControlService) {
        this.absToFormCtr = this._absToForm.convertToFormArray;
    }

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
        if (this.control.value) {
            this.selected = this.control.value;
        }

        this.control.valueChanges.subscribe((res: any[]) => {
            this.selected = res.reduce((a, v: any) => {
                if (v) {
                    a.push(v);
                }
                return a;
            }, []);
        });
    }

    ngOnDestroy() {}

    onChange(arr) {
        this.control.reset();
        this.absToFormCtr(this.control).controls = [];
        for (const v of arr) {
            this.absToFormCtr(this.control).push(new UntypedFormControl(v));
        }
    }
}
