import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { InputImageCroppieComponent } from '@shared/modules/input/image-croppie/image-croppie.component';

@Component({
    selector: 'app-input-multiple-image',
    templateUrl: './image-multiple.component.html',
    styleUrls: ['./image-multiple.component.css'],
    providers: [InputImageCroppieComponent],
})
export class InputMultipleImageComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() control2: UntypedFormControl | AbstractControl;
    @Input() is_delete: boolean = true;
    @Output() onChange = new EventEmitter<any>();

    public tmpForm: UntypedFormGroup;

    public loading: boolean = false;
    public image_url: string;
    public images: any[];

    public deleted: any[];

    private controlSubs$: Subscription;
    private control2Subs$: Subscription;

    constructor(private _fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.deleted = [];

        this.tmpForm = this._fb.group({
            image: new UntypedFormControl(),
        });

        if (this.control.value) {
            this.images = this.control.value;
        }

        this.controlSubs$ = this.control.valueChanges.subscribe((res) => {
            this.images = res;
        });

        if (this.is_delete) {
            this.control2Subs$ = this.control2.valueChanges.subscribe((res) => {
                this.deleted = res;
            });
        }
    }

    ngOnDestroy(): void {
        if (this.controlSubs$) {
            this.controlSubs$.unsubscribe();
        }
        if (this.control2Subs$) {
            this.control2Subs$.unsubscribe();
        }
    }

    public readUrl(event: any): void {
        if (event.target.files && event.target.files[0]) {
            this.loading = true;
            const reader = new FileReader();

            reader.onload = (e: any) => {
                const tmp = this.control.value;
                tmp.push({ file: event.target.files[0], url: e.target.result });
                this.control.setValue(tmp);
                this.loading = false;
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    }

    public onClickDelete(index: number, image: any): void {
        const f2 = _.find(this.images, { url: image.url });
        if (f2 && f2.id) {
            this.deleted.push(f2.id);
            this.control2.setValue(this.deleted);
        }
        this.images.splice(index, 1);
    }

    public tmpImageApply(): void {
        const tmp = { file: null, url: null };
        const toDataURL = (blob: File) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });

        tmp.file = this.tmpForm.get('image').value;
        toDataURL(tmp.file).then((dataUrl) => {
            tmp.url = dataUrl.toString();
        });

        const tmpArr = this.control.value;
        tmpArr.push(tmp);
        this.control.setValue(tmpArr);

        this.tmpForm.patchValue({ image: null });
    }
}
