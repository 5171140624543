<input [disabled]="disabled"
       #date
       id="{{id}}"
       type="text"
       placeholder="{{placeholder}}"
       class="form-control m-input m_datetimepicker"
       autocomplete="off"
       [value]="value"
       readonly
       [class.error]="(control.invalid && control.dirty && control.root.get('submit').value) ||
        (control.pristine && control.invalid && control.root.get('submit').value)"
       (keyup.enter)="evenEnter()" />
