import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    ChildActivationEnd,
} from '@angular/router';
import { Helpers } from './helpers';
import { BackUrl } from '@core/service/back-url';

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    title = 'app';
    globalBodyClass =
        'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

    constructor(private _router: Router) { }

    ngOnInit() {
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true);
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (
                route instanceof NavigationEnd ||
                route instanceof ChildActivationEnd
            ) {
                Helpers.setLoading(false);
            }
        });
    }
}
