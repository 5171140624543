import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';

@Component({
    selector: 'app-input-textarea',
    templateUrl: './textarea.component.html',
    providers: [AbsToFromControlService],
})
export class TextareaComponent implements OnInit {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() rows: number = 5;
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() disabled: boolean | null = null;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('textarea') elemInput: ElementRef;

    public absToFormCtr: Function;

    constructor(private _absToForm: AbsToFromControlService) {
        this.absToFormCtr = this._absToForm.convertToFormControl;
    }

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
