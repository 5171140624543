<div class="app-upload-image">
    <app-loading-states [loading]="loading"></app-loading-states>
    <ng-container *ngIf="images">
        <ng-container *ngFor="let image of images; let index = index">
            <div class="app-upload-image__image"
                 *ngIf="image.url">
                <img [src]="image.url">
                <a *ngIf="is_delete"
                   (click)="onClickDelete(index, image)"
                   class="close"></a>
            </div>
        </ng-container>
    </ng-container>

    <app-input-image-croppie [control]="tmpForm.get('image')"
                             [showApplyBtn]="true"
                             [showEditBtn]="false"
                             (onApply)="tmpImageApply()"></app-input-image-croppie>

    <div *ngIf="is_delete">
        <input class="app-upload-image__file"
               type="file"
               (change)="readUrl($event)">
    </div>
</div>
