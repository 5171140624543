<div class="input-group m-input-group">
    <ng-select [items]="items$ | async"
               class="form-control m-input"
               [ngStyle]="{'width': 'inherit'}"
               bindLabel="name"
               [hideSelected]="true"
               [loading]="loading"
               [typeahead]="itemInput$"
               placeholder="{{placeholder}}"
               [(ngModel)]="selected"
               (change)="onChange($event)">
    </ng-select>
</div>
