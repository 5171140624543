import { Injectable } from '@angular/core';
import { Permissions } from '@core/service/api/methods/auth-check/interface';
import { IMenu } from '@shared/modules/layouts/aside-nav/interface';

@Injectable()
export class AsideNavService {
    constructor() {}

    /**
     * Список менюшки
     *
     * @returns {any[]}
     */
    static getListMenu(permission: Permissions): any[] {
        // Структура меню арма
        const menu: IMenu[] = [
            {
                title: 'Рассылки',
                link: false,
                icon: 'flaticon-alarm-1',
                type: 'notifications',
                children: [
                    {
                        title: 'Push-рассылка',
                        link: false,
                        type: 'push',
                        children: [
                            {
                                title: 'Новая рассылка',
                                link: '/notifications/push/new',
                            },
                            {
                                title: 'Архив рассылок',
                                link: '/notifications/push/archive',
                            },
                        ],
                    },
                    {
                        title: 'СМС установка МП',
                        link: '/notifications/sms-install',
                        type: 'sms-install',
                    },
                    {
                        title: 'Отправка СМС',
                        link: '/notifications/sms-manual',
                        type: 'sms-manual',
                    },
                ],
            },
            {
                title: 'Мегасоветы',
                link: false,
                icon: 'flaticon-interface-1',
                type: 'specials',
                children: [
                    {
                        title: 'Активные',
                        link: '/specials/active',
                        type: 'active',
                    },
                    {
                        title: 'Архивные',
                        link: '/specials/archive',
                        type: 'archive',
                    },
                    {
                        title: 'Авторы',
                        link: '/specials/authors',
                        type: 'authors',
                    },
                ],
            },
            {
                title: 'Заказы',
                link: false,
                icon: 'flaticon-coins',
                type: 'orders',
                children: [
                    {
                        title: false,
                        link: '/orders/view',
                    },
                    {
                        type: 'active',
                        title: 'Активные заказы',
                        link: '/orders/active',
                    },
                    {
                        type: 'archive',
                        title: 'Архивные заказы',
                        link: '/orders/archive',
                    },
                ],
            },
            {
                title: 'Товары',
                link: false,
                icon: 'flaticon-app',
                type: 'items',
                children: [
                    {
                        type: 'search',
                        title: 'Поиск товара',
                        link: '/items/search',
                    },
                    {
                        type: 'merge-items-check',
                        title: 'Возможные дубли',
                        link: '/items/merge-items-check',
                    },
                    {
                        type: 'merge-items-check-barcode',
                        title: 'Двойные связки',
                        link: '/items/merge-items-check-barcode',
                    },
                    {
                        type: 'markup-check',
                        title: 'Разметка ЛС',
                        link: '/items/markup-check',
                    },
                    {
                        type: 'gone',
                        title: 'Вышли из оборота',
                        link: '/items/gone',
                    },
                    {
                        type: 'without-etalon',
                        title: 'Товары без эталона',
                        link: '/items/without-etalon',
                    },
                    {
                        type: 'prices-connections',
                        title: 'Связки от мерчантов',
                        link: '/items/prices-connections',
                    },
                    {
                        type: 'tags',
                        title: 'Теги',
                        link: '/items/tags',
                    },
                    {
                        type: 'goods',
                        title: 'Мегатовары',
                        link: '/items/goods',
                    },
                    {
                        type: 'products',
                        title: 'Продукты',
                        link: '/items/products/list',
                    },
                    {
                        type: 'products-recommendations',
                        title: 'Продукты - предложения товаров',
                        link: '/items/products-recommendations/list',
                    },
                    {
                        type: 'sections',
                        title: 'Разделы',
                        link: '/items/sections',
                    },
                    {
                        type: 'sections-recommendations',
                        title: 'Разделы - предложения товаров',
                        link: '/items/sections-recommendations/list',
                    },
                    {
                        type: 'subscriptions',
                        title: 'Подписки',
                        link: '/items/subscriptions',
                    },
                    {
                        type: 'disable-stores',
                        title: 'Отключение в сети',
                        link: '/items/disable-networks',
                    },
                    {
                        type: 'disable-stores',
                        title: 'Отключение в аптеке',
                        link: '/items/disable-stores',
                    },
                    {
                        type: 'disable-devices',
                        title: 'Отключение у пользователей',
                        link: '/items/disable-devices',
                    },
                    {
                        type: 'disable-check',
                        title: 'Проверка отключения',
                        link: '/items/disable-check',
                    },
                    {
                        type: 'exceptions',
                        title: 'Исключения',
                        link: '/items/exceptions',
                    },
                    {
                        type: 'availability-near',
                        title: 'Наличие рядом',
                        link: '/items/availability-near',
                    },
                    {
                        type: 'availability-city',
                        title: 'Наличие в городе',
                        link: '/items/availability-city',
                    },
                    {
                        type: 'patterns-to-delete',
                        title: 'Шаблоны для отключения товаров',
                        link: '/items/patterns-to-delete',
                    },
                    {
                        type: 'atc-check',
                        title: 'Проверка АТХ',
                        link: '/items/atc-check',
                    },
                    {
                        type: 'order-limit',
                        title: 'Ограничение заказа',
                        link: '/items/order-limit',
                    },
                    {
                        type: 'incompatibility',
                        title: 'Несовместимость',
                        link: '/items/incompatibility',
                    },
                ],
            },
            {
                title: 'Аптеки',
                link: false,
                icon: 'flaticon-map-location',
                type: 'stores',
                children: [
                    {
                        type: 'list',
                        title: 'Список',
                        link: '/stores/list',
                    },
                    {
                        type: 'brands',
                        title: 'Бренды',
                        link: '/stores/brands',
                    },
                    {
                        type: 'empty-cities',
                        title: 'Без городов',
                        link: '/stores/empty-cities',
                    },
                    {
                        type: 'store-networks',
                        title: 'Аптечные сети',
                        link: '/stores/store-networks',
                    },
                    {
                        type: 'problematic-networks',
                        title: 'Проблемные сети',
                        link: '/stores/problematic-networks',
                    },
                    {
                        type: 'merchants',
                        title: 'Мерчанты',
                        link: '/stores/merchants',
                    },
                    {
                        type: 'control',
                        title: 'Аптечный контроль',
                        link: '/stores/control',
                    },
                ],
            },
            {
                title: 'Отчеты',
                link: false,
                icon: 'flaticon-line-graph',
                type: 'reports',
                children: [
                    {
                        type: 'reward',
                        title: 'Вознаграждение',
                        link: '/reports/reward',
                    },
                    {
                        type: 'cancel-orders',
                        title: 'Отмены заказов',
                        link: '/reports/cancel-orders',
                    },
                    {
                        type: 'top-items',
                        title: 'Топ товаров',
                        link: '/reports/top-items',
                    },
                    {
                        type: 'nomenclature',
                        title: 'Номенклатура',
                        link: '/reports/nomenclature',
                    },
                ],
            },
            {
                title: 'Контент',
                link: false,
                icon: 'flaticon-interface-4',
                type: 'content',
                children: [
                    {
                        type: 'feedback-items',
                        title: 'Отзывы о товарах',
                        link: '/content/feedback-items',
                    },
                    {
                        type: 'smi-about',
                        title: 'СМИ о нас',
                        link: '/content/smi-about',
                    },
                    {
                        type: 'cities-seo',
                        title: 'SEO-тексты городов',
                        link: '/content/cities-seo',
                    },
                    {
                        type: 'partners',
                        title: 'Партнеры',
                        link: '/content/partners',
                    },
                ],
            },
            {
                title: 'Клиенты',
                link: false,
                icon: 'flaticon-user',
                type: 'clients',
                children: [
                    {
                        type: 'password',
                        title: 'Смена пароля',
                        link: '/clients/password',
                    },
                    {
                        type: 'blocklist',
                        title: 'Блоклист',
                        link: '/clients/blocklist',
                    },
                ],
            },
            {
                title: 'Аптечный мониторинг',
                link: false,
                icon: 'flaticon-diagram',
                type: 'pharmacy-monitoring',
                children: [
                    {
                        type: 'vendors',
                        title: 'Вендоры',
                        link: '/pharmacy-monitoring/vendors',
                    },
                ],
            },
            {
                title: 'Поиск',
                link: false,
                icon: 'flaticon-search',
                type: 'search',
                children: [
                    {
                        type: 'synonyms',
                        title: 'Синонимы',
                        link: '/search/synonyms',
                    },
                    {
                        type: 'synonyms-name',
                        title: 'Синонимы по названию',
                        link: '/search/synonyms-name',
                    },
                    {
                        type: 'synonyms-translit',
                        title: 'Синонимы с транслитерацией',
                        link: '/search/synonyms-translit',
                    },
                    {
                        type: 'ignore',
                        title: 'Исключения',
                        link: '/search/ignore',
                    },
                ],
            },
        ];

        // Проверка
        const tmpMenu = [];
        for (const v of menu) {
            let tmp;
            // 1 уровень
            if (permission[v.type]) {
                const permis: string[] = permission[v.type];
                tmp = JSON.parse(JSON.stringify(v));

                // 2 уровень
                if (v.children.length > 0) {
                    tmp['children'] = [];
                    for (const j of v.children) {
                        if (permis.indexOf(j.type) !== -1) {
                            tmp['children'].push(j);
                        }
                    }
                }
            }
            if (tmp) {
                tmpMenu.push(tmp);
            }
        }

        return tmpMenu;
    }
}
