import { Component, Input, OnInit } from '@angular/core';
import { concat, from, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
} from 'rxjs/operators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

/**
 * @deprecated Замена select-autocomplete
 */
@Component({
    selector: 'app-search-autocomplete',
    templateUrl: './select-find-autocomplete.component.html',
})
export class SelectFindAutocompleteV2Component implements OnInit {
    @Input() group: UntypedFormGroup | AbstractControl;
    @Input() bindLabel: string;
    @Input() bindValue: string;
    @Input() funcSearch: Function;
    @Input() placeholder: string = '';

    public items$: Observable<any>;
    public list: any;
    public selected = {};
    public loading = false;
    public typeahead$ = new Subject<any>();

    constructor() {}

    ngOnInit() {
        if (
            this.group.get(this.bindLabel).value &&
            this.group.get(this.bindValue).value
        ) {
            this.selected[this.bindLabel] = this.group.get(
                this.bindLabel
            ).value;
            this.selected[this.bindValue] = this.group.get(
                this.bindValue
            ).value;
        }
        this.load();
    }

    private load() {
        this.items$ = concat(
            of([]),
            this.typeahead$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.loading = true)),
                switchMap((term) => {
                    return from(
                        this.funcSearch(term).then((resApi) => {
                            return resApi.list;
                        })
                    ).pipe(
                        catchError(() => of([])),
                        tap(() => (this.loading = false))
                    );
                })
            )
        );
    }

    onChange(event) {
        if (event !== undefined) {
            this.selected = event;
            this.group.get(this.bindLabel).setValue(event[this.bindLabel]);
            this.group.get(this.bindValue).setValue(event[this.bindValue]);
        } else {
            console.log('onChange(event) undefined 2');
        }
    }
}
