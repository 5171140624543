import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';

@Component({
    selector: 'app-input-radio',
    templateUrl: 'radio.component.html',
    providers: [AbsToFromControlService],
})
export class RadioComponent implements OnInit {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() name: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() options: { name: string; value: string }[] = [];

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('radio') elemInput: ElementRef;

    public absToFormCtr: Function;

    constructor(private _absToForm: AbsToFromControlService) {
        this.absToFormCtr = this._absToForm.convertToFormControl;
    }

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
