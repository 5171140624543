<div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-3"
     id="m_login"
     style="background-image: url(./assets/app/media/img//bg/bg-2.jpg);">
    <div class="m-grid__item m-grid__item--fluid m-login__wrapper">
        <div class="m-login__container">
            <div class="m-login__logo">
                <a href="#">
                    <img width="124"
                         height="100%"
                         src="./assets/app/media/img/logos/logoMA.svg">
                </a>
            </div>
            <div class="m-login__signin">
                <div class="m-login__head">
                    <h3 class="m-login__title">
                        Автоматизированное рабочее место оператора
                    </h3>
                </div>
                <form [formGroup]="form"
                      (ngSubmit)=" OnClickSignIn()"
                      #f="ngForm"
                      class="m-login__form m-form"
                      action="">
                    <div *ngIf="messageFromApi"
                         class="m-alert m-alert--outline alert alert-danger alert-dismissible"
                         role="alert">
                        <span>{{messageFromApi}}</span>
                    </div>
                    <div *ngIf="form.get('login').invalid && submit"
                         class="m-alert m-alert--outline alert alert-danger alert-dismissible"
                         role="alert">
                        <span>Не заполнено поле: Имя пользователя</span>
                    </div>
                    <div *ngIf="form.get('password').invalid && submit"
                         class="m-alert m-alert--outline alert alert-danger alert-dismissible"
                         role="alert">
                        <span>Не заполнено поле: Пароль</span>
                    </div>
                    <div class="form-group m-form__group">
                        <input formControlName="login"
                               class="form-control m-input"
                               type="text"
                               placeholder="Имя пользователя"
                               name="login"
                               autocomplete="off">
                    </div>
                    <div class="form-group m-form__group">
                        <input formControlName="password"
                               class="form-control m-input m-login__form-input--last"
                               type="password"
                               placeholder="Пароль"
                               name="password">

                    </div>
                    <div class="m-login__form-action">
                        <button [disabled]="loading"
                                [ngClass]="{'m-loader m-loader--right m-loader--light': loading}"
                                class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn">
                            Войти
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
