import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';

@Component({
    selector: 'app-input-email',
    templateUrl: 'email.component.html',
    providers: [AbsToFromControlService],
})

/**
 * Нужно переделать как в text
 */
export class EmailComponent implements OnInit {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() submit: boolean = false;
    @Input() type: string = 'grey';
    @Input() placeholder: string = 'Ваш e-mail';
    @Input() prefix: string = '';
    @Input() showText: boolean = true;
    @Input() focus: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('email') elemInput: ElementRef;

    public name: string = 'email';
    public isShow: boolean = true;
    public inputClass: string;

    public absToFormCtr: Function;

    constructor(private _absToForm: AbsToFromControlService) {
        this.absToFormCtr = this._absToForm.convertToFormControl;
    }

    ngOnInit() {
        switch (this.type) {
            case 'grey':
                this.inputClass = 'form6';
                break;
            case 'grey2':
                this.inputClass = 'form7';
                break;
        }

        if (this.prefix.length > 0) {
            this.name = `${this.prefix}_${this.name}`;
        }

        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
