<app-header-nav></app-header-nav>
<!-- begin::Body -->
<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
    <app-aside-nav></app-aside-nav>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <router-outlet></router-outlet>

        <div class="m-page-loader m-page-loader--base m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">
            <div class="m-blockui">
		<span>
			Загрузка...
		</span>
                <span>
			<div class="m-loader m-loader--brand"></div>
		</span>
            </div>
        </div>
    </div>
</div>
<!-- end:: Body -->
<app-footer></app-footer>
