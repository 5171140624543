<div class="input-group m-input-group">
    <ng-select
            #select_find_multiple
            style="width: 100%;"
            bindLabel="name"
            placeholder="{{placeholder}}"
            [multiple]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            [clearable]="clearable"
            [hideSelected]="true"
            [(ngModel)]="selected"
            [searchable]="search"
            notFoundText="{{notFoundText}}"
            (change)="onChange($event)">
        <ng-container *ngFor="let op of options">
            <ng-option [value]="op.id">
                {{op.name}}
            </ng-option>
            <ng-container *ngIf="op.children">
                <ng-container *ngFor="let ch of op.children">
                    <ng-option [value]="ch.id">
                        . . {{ch.name}}
                    </ng-option>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-select>
    <div class="input-group-append" *ngIf="loading">
        <div class="m-loader m-loader--brand" style="width: 30px; display: inline-block;"></div>
    </div>
</div>
