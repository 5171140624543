import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Config } from '@core/service/config';
import * as moment from 'moment-mini-ts';

@Component({
    selector: 'app-input-date-range',
    templateUrl: 'date-range.component.html',
})
export class InputDateRangeComponent implements OnInit, AfterViewInit {
    @Input() controlStart: UntypedFormControl | AbstractControl;
    @Input() controlEnd: UntypedFormControl | AbstractControl;
    @Input() controlActive: UntypedFormControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() isEmpty: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('date') elemInput: ElementRef;

    public value: string = '';
    public format: string = '';

    constructor(private _config: Config) { }

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }

        const configSite = this._config.get('site');
        this.format = configSite.date.date;

        if (!this.isEmpty) {
            if (!this.controlStart.value || !this.controlEnd.value) {
                const start = moment();
                const end = moment();
                this.controlStart.setValue(start.unix());
                this.controlEnd.setValue(end.add(1, 'month').unix());
                if (this.controlActive) {
                    const diff = end.diff(start, 'days');
                    this.controlActive.setValue(diff + 1);
                }
            } else {
                this.formatDateInput(
                    moment.unix(this.controlStart.value),
                    moment.unix(this.controlEnd.value)
                );
            }
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }

    ngAfterViewInit() {
        const daterangepicker = $('.m_datepicker_range');
        daterangepicker.daterangepicker(
            {
                buttonClasses: 'm-btn btn',
                applyClass: 'btn-primary',
                cancelClass: 'btn-secondary',
                startDate: moment
                    .unix(this.controlStart.value)
                    .format(this.format),
                endDate: moment.unix(this.controlEnd.value).format(this.format),
                locale: {
                    format: this.format,
                    separator: ' до ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    fromLabel: 'От',
                    timeZone: 'utc',
                    toLabel: 'До',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: [
                        'Январь',
                        'Февраль',
                        'Март',
                        'Апрель',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Август',
                        'Сентябрь',
                        'Октябрь',
                        'Ноябрь',
                        'Декабрь',
                    ],
                    firstDay: 1,
                },
            },
            (start, end, label) => {
                this.formatDateInput(start, end);

                this.controlStart.setValue(start.unix());
                this.controlEnd.setValue(end.unix());

                if (this.controlActive) {
                    const diff = end.diff(start, 'days');
                    this.controlActive.setValue(diff + 1);
                }
            }
        );
    }

    formatDateInput(start, end) {
        this.value =
            'c ' + start.format(this.format) + ' до ' + end.format(this.format);
    }
}
