import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LazyScript } from '@core/service/lazy-script';
import { UUID } from 'angular2-uuid';
import { Config } from '@core/service/config';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';

@Component({
    selector: 'app-input-coordinates',
    templateUrl: 'coordinates.component.html',
    styleUrls: ['coordinates.component.css'],
    providers: [AbsToFromControlService],
})
export class CoordinatesComponent implements OnInit, OnDestroy {
    @Input() controlLatitude: UntypedFormControl | AbstractControl;
    @Input() controlLongitude: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() city_name: string = '';
    @Input() placeholderLatitude: string = '';
    @Input() placeholderLongitude: string = '';
    @Input() focus: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('latitude') elemInputLatitude: ElementRef;
    @ViewChild('longitude') elemInputLongitude: ElementRef;

    private _config: Config = new Config();

    public mapId: string;
    public submit: boolean = false;
    private submit$: Subscription;

    private map: any;
    private mapDefaultZoom: number = 16;
    private geoObject: any;
    private apiKey: string;
    private controlLatitude$: Subscription;
    private controlLongitude$: Subscription;

    public absToFormCtr: Function;

    constructor(
        private _lazyScript: LazyScript,
        private _absToForm: AbsToFromControlService
    ) {
        this.absToFormCtr = this._absToForm.convertToFormControl;
    }

    ngOnInit() {
        if (+this.controlLatitude.value + +this.controlLongitude.value === 0) {
            this.mapDefaultZoom = 8;
        }

        this.mapId = UUID.UUID();
        this.apiKey = <string>this._config.get('yandex_key');
        this._lazyScript
            .load(
                'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=' +
                this.apiKey,
                true,
                false
            )
            .then((res) => {
                try {
                    ymaps.ready(() => {
                        this.map = new ymaps.Map(
                            this.mapId,
                            {
                                zoom: this.mapDefaultZoom,
                                center: this.getFormCoords(),
                                controls: ['zoomControl', 'fullscreenControl'],
                            },
                            {
                                searchControlProvider: 'yandex#search',
                            }
                        );

                        if (
                            this.controlLatitude.value &&
                            this.controlLongitude.value
                        ) {
                            this.installMapCoordinates();
                        } else {
                            if (this.city_name) {
                                this.installMapCity();
                            }
                        }
                    });
                } catch (e) { }
            });

        this.controlLatitude$ = this.controlLatitude.valueChanges.subscribe(
            (res) => {
                const coordinates: number[] = this.getFormCoords();
                this.geoObject.geometry.setCoordinates(coordinates);
                this.map.panTo(coordinates, {
                    delay: 1500,
                });
            }
        );

        this.controlLongitude$ = this.controlLongitude.valueChanges.subscribe(
            (res) => {
                const coordinates: number[] = this.getFormCoords();
                this.geoObject.geometry.setCoordinates(coordinates);
                this.map.panTo(coordinates, {
                    delay: 1500,
                });
            }
        );
    }

    ngOnDestroy() {
        if (this.submit$) {
            this.submit$.unsubscribe();
        }
        if (this.controlLatitude$) {
            this.controlLatitude$.unsubscribe();
        }
        if (this.controlLongitude$) {
            this.controlLongitude$.unsubscribe();
        }
    }

    getFormCoords(): number[] {
        return [
            +this.controlLatitude.value || 55.753215,
            +this.controlLongitude.value || 37.622504,
        ];
    }

    setFormCoords(): void {
        const coords = this.geoObject.geometry.getCoordinates();
        this.controlLongitude.setValue(coords[1]);
        this.controlLatitude.setValue(coords[0]);
    }

    installMapCoordinates() {
        const coordinates: number[] = this.getFormCoords();

        // Установка центра
        this.map.setCenter(coordinates);

        // Гео объект
        this.geoObject = new ymaps.GeoObject(
            {
                geometry: {
                    type: 'Point',
                    coordinates: coordinates,
                },
                properties: {
                    iconContent: 'Аптека',
                    hintContent: 'Ну давай уже тащи',
                },
            },
            {
                preset: 'islands#redStretchyIcon',
                draggable: true,
            }
        );

        // Добавляем гео объекты
        this.map.geoObjects.add(this.geoObject);
        this.addEventDragObject();
    }

    installMapCity() {
        ymaps
            .geocode(this.city_name, {
                results: 1,
            })
            .then((res) => {
                const firstGeoObject = res.geoObjects.get(0);
                const coords = firstGeoObject.geometry.getCoordinates();
                const bounds = firstGeoObject.properties.get('boundedBy');

                this.controlLongitude.setValue(coords[0]);
                this.controlLatitude.setValue(coords[1]);

                // Гео объект
                this.geoObject = new ymaps.GeoObject(
                    {
                        geometry: {
                            type: 'Point',
                            coordinates: coords,
                        },
                        properties: {
                            iconContent: 'Аптека',
                            hintContent: 'Ну давай уже тащи',
                        },
                    },
                    {
                        preset: 'islands#redStretchyIcon',
                        draggable: true,
                    }
                );

                // Добавляем гео объекты
                this.map.geoObjects.add(this.geoObject);
                this.map.setBounds(bounds, {
                    checkZoomRange: true,
                });

                this.addEventDragObject();
            });
    }

    addEventDragObject() {
        this.geoObject.events.add('drag', (event) => {
            this.setFormCoords();
        });
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
