import { Injectable } from '@angular/core';
import { ApiRequest, Response } from '@core/service/api/request';
import { InPost } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from '@core/service/api/utils';

@Injectable({
    providedIn: 'any',
})
export class ApiAuthLoginService extends ApiUtilsService {
    constructor(
        protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config
    ) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Получения токена
     *
     * @param {InPost} obj
     * @return {Promise<InPost>}
     */
    put(obj: InPost) {
        return this._request
            .put('ma/arm/v1/auth/login', obj)
            .then((res: Response) => {
                return res.json;
            });
    }
}
