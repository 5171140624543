import { Injectable } from '@angular/core';
import { Auth } from './auth';
import { Config } from '../config';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class Guard implements CanActivate, CanActivateChild {
    private active: boolean = false;

    constructor(
        private _auth: Auth,
        private _router: Router,
        private _config: Config
    ) {
        //this.checkAuthTime();
        // this.logoutTime();
    }

    /**
     *
     * @returns {boolean}
     */
    canActivate(): Promise<boolean> {
        return this._auth.check().then((res: boolean) => {
            this.active = res;
            if (res === true) {
                return true;
            }
            this._router.navigate(['/login']);
            return false;
        });
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (route.routeConfig.data && route.routeConfig.data.permissions) {
            const prm = route.routeConfig.data.permissions;
            this._auth.checkUrlPath(`${prm.parent}/${prm.code}`);
        } else if (this._auth.checkUrlPath(state.url)) {
            return true;
        } else {
            this._router.navigate(['/']);
            return false;
        }
    }

    checkAuthTime() {
        const config = this._config.get('site');
        let checkAuthTime = config.checkAuthTime;
        if (!checkAuthTime) {
            checkAuthTime = 5;
        }
        const intervalObs = interval(1000 * checkAuthTime);

        intervalObs
            .pipe(
                filter(() => {
                    return this.active;
                })
            )
            .subscribe(() => {
                this._auth.check().then((resApi) => {
                    this.active = resApi;
                    if (resApi === false) {
                        this._router.navigate(['/login']);
                    }
                });
            });
    }

    logoutTime() {
        const config = this._config.get('site');
        let logoutTime = config.logoutTime;
        if (!logoutTime) {
            logoutTime = 30;
        }
        const intervalObs = interval(1000 * 60 * logoutTime);

        intervalObs
            .pipe(
                filter(() => {
                    return this.active;
                })
            )
            .subscribe(() => {
                this._auth.logout().then((resApi) => {
                    this.active = false;
                    this._router.navigate(['/login']);
                });
            });
    }
}
