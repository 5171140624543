import { Injectable } from '@angular/core';

@Injectable()
export class LazyScript {
    private scriptLoadingPromise: any = {};

    /**
     * Загружает скрипты
     *
     * @param {string} _url
     * @param {boolean} _async
     * @param {boolean} _defer
     * @returns {Promise<void>}
     */
    public load(_url: string, _async: boolean = false, _defer: boolean = false): Promise<void> {
        const name: string = encodeURI(_url);

        if (!this.find(_url)) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = _async;
            script.defer = _defer;
            script.src = _url;

            // tslint:disable-next-line:ban-types
            this.scriptLoadingPromise[name] = new Promise<void>((resolve: Function, reject: Function) => {
                script.onload = () => {
                    resolve();
                };
                script.onerror = (error: Event) => {
                    reject(error);
                };
            });
            document.body.appendChild(script);
            return this.scriptLoadingPromise[name];
        } else {
            return new Promise<void>((res) => {
                res(this.scriptLoadingPromise[name]);
            });
        }
    }

    /**
     * Поиск уже загруженного скрипта
     *
     * @param _url
     * @returns {boolean}
     */
    private find(_url): boolean {
        const script = document.getElementsByTagName('script');
        for (let i = 0; i < script.length; i++) {
            const status = script[i].getAttribute('src');
            if (status === _url) {
                return true;
            }
        }

        return false;
    }
}
