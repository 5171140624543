<div class="input-daterange input-group">
    <input #latitude
           type="number"
           id="{{id}}"
           placeholder="{{placeholderLatitude}}"
           class="form-control m-input"
           autocomplete="off"
           [formControl]="absToFormCtr(controlLatitude)"
           [class.error]="(controlLatitude.invalid && controlLatitude.dirty && submit) ||
            (controlLatitude.pristine && controlLatitude.invalid && submit)"
           (keyup.enter)="evenEnter()" />
    <div class="input-group-append">
        <span class="input-group-text">
            <i class="la flaticon-map-location"></i>
        </span>
    </div>
    <input #longitude
           type="number"
           placeholder="{{placeholderLongitude}}"
           class="form-control m-input"
           autocomplete="off"
           [formControl]="absToFormCtr(controlLongitude)"
           [class.error]="(controlLongitude.invalid && controlLongitude.dirty && submit) ||
            (controlLongitude.pristine && controlLongitude.invalid && submit)"
           (keyup.enter)="evenEnter()" />
</div>
<div id="{{mapId}}"
     class="map-yandex"></div>
