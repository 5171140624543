<div class="input-group m-input-group"
     [class.error]="(control.invalid && control.dirty && submit) ||
            (control.pristine && control.invalid && submit)">
    <ng-select [typeahead]="typeahead"
               #select_find
               style="width: 100%;"
               bindLabel="{{bindLabel}}"
               placeholder="{{placeholder}}"
               [(ngModel)]="selected"
               [searchable]="search"
               [disabled]="disabled"
               [loading]="loading"
               notFoundText="{{notFoundText}}"
               (change)="onChange($event)"
               [clearable]="clearable">
        <ng-container *ngFor="let op of options">
            <ng-option [value]="op[bindValue]">
                {{op[bindLabel]}}
            </ng-option>
            <ng-container *ngIf="op.children">
                <ng-container *ngFor="let ch of op.children">
                    <ng-option [value]="ch[bindValue]">
                        . . {{ch[bindLabel]}}
                    </ng-option>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-select>
</div>
