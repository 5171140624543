<div class="select" style="width: 100%;">
    <select [disabled]="disabled" #multi_select class="form-control m-select2 multi_select" id="m_select2_3" multiple="multiple">
            <option></option>
            <ng-container *ngFor="let op of options">
                <option value="{{op.id}}" [ngValue]="op.id" [selected]="op.selected">
                    {{op.name}}
                </option>
            </ng-container>
    </select>
</div>
