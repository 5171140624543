import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs/index';
import { Toast } from '@core/service/toast';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';

@Component({
    selector: 'app-input-number',
    templateUrl: 'number.component.html',
    providers: [AbsToFromControlService],
})
export class NumberComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() needValidation: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('number') elemInput: ElementRef;

    private submit$: Subscription;
    public submit: boolean = false;

    private oldValue: string;

    public absToFormCtr: Function;

    constructor(
        private _toast: Toast,
        private _absToForm: AbsToFromControlService
    ) {
        this.absToFormCtr = this._absToForm.convertToFormControl;
    }

    ngOnInit() {
        this.oldValue = this.control.value;

        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }

        if (this.control.root.get('submit') instanceof UntypedFormControl) {
            this.submit$ = this.control.root
                .get('submit')
                .valueChanges.subscribe((res) => {
                    this.submit = res;
                });
        }
    }

    ngOnDestroy() {
        if (this.submit$) {
            this.submit$.unsubscribe();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }

    validate(e) {
        if (this.needValidation) {
            if (!!isNaN(e.data)) {
                e.target.value = this.oldValue;
                this._toast.error('Поле принимает только числа');
            } else {
                this.oldValue = e.target.value;
            }
        }
    }
}
