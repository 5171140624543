import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';
import { Subscription } from 'rxjs/index';

@Component({
    selector: 'app-input-text',
    templateUrl: 'text.component.html',
    providers: [AbsToFromControlService],
})
export class TextComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() disabled: boolean | null = null;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('text') elemInput: ElementRef;

    private submit$: Subscription;
    public submit: boolean = false;
    public disabledParsed: boolean | null = null;

    public absToFormCtr: Function;

    constructor(private _absToForm: AbsToFromControlService) {
        this.absToFormCtr = this._absToForm.convertToFormControl;
    }

    ngOnInit() {
        if (this.focus && this.elemInput) {
            this.elemInput.nativeElement.focus();
        }

        if (this.control.root.get('submit') instanceof UntypedFormControl) {
            this.submit$ = this.control.root
                .get('submit')
                .valueChanges.subscribe((res) => {
                    this.submit = res;
                });
        }

        if (this.disabled) {
            this.disabledParsed = true;
        }
    }

    ngOnDestroy() {
        if (this.submit$) {
            this.submit$.unsubscribe();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
