<!-- begin::Page loader -->
<div class="m-page-loader m-page-loader--base m-page-loader--non-block"
     style="margin-left: -80px; margin-top: -20px;">
    <div class="m-blockui">
        <span>
            Загрузка...
        </span>
        <span>
            <div class="m-loader m-loader--brand"></div>
        </span>
    </div>
</div>
<!-- end::Page loader -->
<!-- begin:: Page -->
<router-outlet></router-outlet>
<!-- end:: Page -->
<app-scroll-top></app-scroll-top>
