import { Component, OnInit } from '@angular/core';
import { Auth } from '@core/service/auth';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styles: []
})
export class LogoutComponent implements OnInit {

    constructor(private _auth: Auth) {
    }

    ngOnInit() {
    }

    onClick() {
        this._auth.logout()
            .then(() => {
                window.location.href = '/';
            });
    }

}
