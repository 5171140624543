import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as _ from "lodash";

@Component({
    selector: 'app-multi-select',
    templateUrl: 'multi-select.component.html'
})
export class MultiSelectComponent implements OnInit, AfterViewInit {
    @Input() control: UntypedFormControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() disabled: boolean = false;
    @Input() options: any[];
    private list: any;

    @Output() enter = new EventEmitter<boolean>();
    @Output() change = new EventEmitter<any>();

    @ViewChild('select') elemInput: ElementRef;

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
    }

    ngAfterViewInit() {
        const select = $('.multi_select');
        select.select2({
            minimumResultsForSearch: -1,
            placeholder: 'Выберите ...',
            language: {
                noResults: function() {
                    return 'Не найдено';
                }
            },
        });
        select.on(
            'change',
            (e) => {
                this.list = $(e.target).val();
                let newList: number[] = [];
                _.forEach(this.list, (v) => {
                    newList.push(Number(v));
                });
                this.control.setValue(newList);
            })
    }
}
