import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Refresh {
    public routerOutlet: Subject<boolean> = new Subject();

    /**
     * Перезагружает router-outlet
     */
    public routerOutletRefresh() {
        this.routerOutlet.next(false);
        setTimeout(() => {
            this.routerOutlet.next(true);
        }, 10);

    }
}
