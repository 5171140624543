import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class Toast {
    constructor(private _toaster: ToastrService) { }

    /**
     * Удачный ответ
     *
     * @param {string} text
     */
    success(text: string): void {
        this._toaster.success(text, '', { timeOut: 5000 });
    }

    /**
     * Ошибка
     *
     * @param {string} text
     */
    error(text: string = 'Произошла ошибка'): void {
        this._toaster.error(text, '', { timeOut: 10000 });
    }
}


