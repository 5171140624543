import { Injectable } from '@angular/core';
import { GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';
import { Session } from '../session';
import { filter } from 'rxjs/operators';

@Injectable()
export class BackUrl {
    public url: string;
    private rx: any;
    private listBlack = ['login/*'];
    private nameLS = 'backurl';

    constructor(private _router: Router, private _ss: Session) {
        this.track();
    }

    /**
     * Запись урлов переходов
     */
    public track() {
        this.rx = this._router.events
            .pipe(
                filter((event) => {
                    return (
                        event instanceof GuardsCheckEnd ||
                        event instanceof NavigationEnd
                    );
                })
            )
            .subscribe((param: NavigationEnd) => {
                this.url = param.url;
                if (this.check()) {
                    this.save();
                }
            });
    }

    /**
     * Ридерект обратно, если есть урл
     */
    public redirect() {
        let url = this.getUrl();
        let urlNow = this._router.url.toString().replace(/\?.*/gi, '');
        if (!url) {
            url = '/';
        }
        if (url === urlNow) {
            this._router.navigate([urlNow], { queryParams: { ref: 1 } });
        } else {
            this._router.navigateByUrl(url);
        }
    }

    public set(url: string): void {
        this._ss.set(this.nameLS, url);
    }

    /**
     * Возращает урл
     *
     * @returns {string}
     */
    public getUrl(): string {
        return <string>this._ss.get(this.nameLS);
    }

    /**
     *  Убеваем запись урлов переходов
     */
    public destroy() {
        this.rx.unsubscribe();
    }

    /**
     *
     *  Проверка урла в списке запрещенных
     * @returns {boolean}
     */
    private check(): boolean {
        let trigger = true;
        for (const v of this.listBlack) {
            const t = new RegExp(v, '');
            if (t.test(this.url)) {
                trigger = false;
            }
        }

        return trigger;
    }

    /**
     * Сохраняем урл в localstore
     */
    private save() {
        const url = this.url !== '/' ? this.url : '';
        this.set(url);
    }
}
