import { Inject, Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { forEach, set, size } from 'lodash';
import { Cookie } from '../cookie';
import { CookieService } from 'ngx-cookie';
import { Config } from '../config';
import { Log } from '../logger';
import { DOCUMENT } from '@angular/common';

export interface Response {
    status: number;
    json: any;
}

@Injectable({
    providedIn: 'root',
})
export class ApiRequest {
    protected data: {} = {};
    protected json: string;

    constructor(
        private _http: HttpClient,
        private _config: Config,
        private _log: Log,
        private _cookie: Cookie,
        private _cookieService: CookieService,
        @Inject(DOCUMENT) private _document
    ) {}

    /**
     *
     *
     * @param {string} _path
     * @param {Object} _data
     * @param {boolean} file
     * @return {Promise<Response>}
     */
    public get(_path: string, _data: object = {}, file: boolean = false) {
        this.params(_data);
        const json: string = this.json;

        const urlObj = new URL(this.createUrl(_path));
        if (json !== '') {
            urlObj.searchParams.set('data', json);
        }

        const url: string = urlObj.toString();
        this._log.debug('GET', url);

        const headers: any = {
            headers: this.header(),
        };
        if (file) {
            headers.responseType = 'blob';
        }

        return this._http
            .get(url, headers)
            .toPromise()
            .then((body: any) => {
                // Если в ответе будет файл
                if (file) {
                    return body;
                }
                return this.reply(body);
            })
            .catch((error: HttpErrorResponse) => {
                throw this.replyError(error);
            });
    }

    /**
     * Обновления
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public put(_path: string, _data: object = {}) {
        const listFile: Object = {};
        const listData: Object = {};
        const formData: FormData = new FormData();
        let isFile = false;
        forEach(_data, (v: any, k) => {
            if (v instanceof File || v instanceof Blob) {
                set(listFile, k, v);
            } else if (v instanceof Array) {
                forEach(v, (vv: any, kk) => {
                    if (vv instanceof File) {
                        formData.append(k, vv, vv.name);
                        isFile = true;
                    } else {
                        set(listData, k, v);
                    }
                });
            } else {
                set(listData, k, v);
            }
        });
        this.params(listData);
        const url: string = this.createUrl(_path);
        let header = this.header();

        let body: any;
        if (size(listFile) > 0 || isFile) {
            formData.append('data', this.json);

            forEach(listFile, (v: any, k) => {
                formData.append(k, v, v.name);
            });

            body = formData;
            header = header.delete('Content-Type', '');
        } else {
            body = this.json;
        }

        this._log.debug('PUT', url);

        return this._http
            .put(url, body, { headers: header })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);
            })
            .catch((error: HttpErrorResponse) => {
                throw this.replyError(error);
            });
    }

    /**
     * Создания
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public post(_path: string, _data: object = {}) {
        const listFile: Object = {};
        const listData: Object = {};
        const formData: FormData = new FormData();
        let isFile = false;
        forEach(_data, (v: any, k) => {
            if (v instanceof File || v instanceof Blob) {
                set(listFile, k, v);
            } else if (v instanceof Array && v.length > 0) {
                forEach(v, (vv: any, kk) => {
                    if (vv instanceof File) {
                        formData.append(k, vv, vv.name);
                        isFile = true;
                    } else {
                        set(listData, k, v);
                    }
                });
            } else {
                set(listData, k, v);
            }
        });
        this.params(listData);
        const url: string = this.createUrl(_path);
        let header = this.header();

        let body: any;
        if (size(listFile) > 0 || isFile) {
            formData.append('data', this.json);

            forEach(listFile, (v: any, k) => {
                formData.append(k, v, v.name);
            });

            body = formData;
            header = header.delete('Content-Type', '');
        } else {
            body = this.json;
        }

        return this._http
            .post(url, body, { headers: header })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);
            })
            .catch((error: HttpErrorResponse) => {
                throw this.replyError(error);
            });
    }

    /**
     * Удаление
     *
     * @param {string} _path
     * @param {Object} _data
     * @return {Promise<Response>}
     */
    public delete(_path: string, _data: object = {}) {
        const listData: Object = {};
        forEach(_data, (v: any, k) => {
            set(listData, k, v);
        });
        this.params(listData);
        const url: string = this.createUrl(_path);

        return this._http
            .request('delete', url, { headers: this.header(), body: this.json })
            .toPromise()
            .then((res: Response) => {
                return this.reply(res);
            })
            .catch((error: HttpErrorResponse) => {
                throw this.replyError(error);
            });
    }

    /**
     * Возращает отформатированный ответ из запроса
     *
     * @param {Response} res
     * @return {ApiResponse}
     */
    private reply(res: Response) {
        const reply = {
            json: res,
        };
        return <Response>reply;
    }

    /**
     * Возращает отформатированный ответ ошибки
     *
     * @param {HttpErrorResponse} res
     *
     * @return {}
     */
    private replyError(res: HttpErrorResponse) {
        const reply = {
            message: res.error.message,
            status: res.status,
        };

        return <{ message: string; status: number }>reply;
    }

    /**
     * Создания url
     *
     * @param path
     * @returns {string}
     */
    protected createUrl(path: string): string {
        let urlPath = '';
        const api = this._config.get('api');
        const port = api['port'];
        const protocol = api['protocol'];
        const host = api['url'];
        const queryParams = api['queryParams'] || null;

        urlPath += protocol + '://';
        urlPath += host;
        if (port) {
            urlPath += ':' + port;
        }
        urlPath += '/' + path + '/';

        const url = new URL(urlPath);

        if (Array.isArray(queryParams)) {
            queryParams.forEach((item) => {
                url.searchParams.set(item.key, item.value);
            });
        }

        return url.toString();
    }

    /**
     * Возращает header
     *
     * @param params
     * @returns {Headers}
     */
    protected header(params: {} = {}): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({});
        const configHeader = this._config.get('api')['header'];

        /**
         * Конфиг
         */
        forEach(configHeader, function (val, key) {
            headers = headers.append(key.toString(), val.toString());
        });

        /**
         * Входящие параметры
         */
        if (params) {
            forEach(params, function (val: any, key: any) {
                headers = headers.set(<string>key, <string>val);
            });
        }

        /**
         * Токен
         */
        const authToken = this._cookieService.get('auth_token')
            ? this._cookieService.get('auth_token')
            : '';
        if (authToken.length > 0) {
            headers = headers.append('auth_token', authToken);
        }

        return headers;
    }

    /**
     * Подготовка данных
     *
     * @param _data обьект с данными
     *
     * @returns {string}
     */
    protected params(_data: object): void {
        this.data = _data;
        this.json = <string>JSON.stringify(this.data);
    }
}
