import {
    Component,
    OnInit,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { IMenu } from './interface';
import { AsideNavService } from './aside-nav.service';
import { Auth } from '@core/service/auth';

declare let mLayout: any;

@Component({
    selector: 'app-aside-nav',
    templateUrl: './aside-nav.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {
    public list: IMenu[];

    constructor(private _auth: Auth) {}

    ngOnInit() {
        this._auth.permission.subscribe((res) => {
            this.list = AsideNavService.getListMenu(res);
        });
    }

    ngAfterViewInit() {
        mLayout.initAside();
    }
}
