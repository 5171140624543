import { Injectable } from '@angular/core';
import { ApiRequest } from './request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import * as _ from 'lodash';

@Injectable()
export class ApiUtilsService {
    constructor(
        protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config
    ) {}

    /**
     * Количество по умолчанию (все 500)
     *
     * @param obj
     * @returns {any}
     */
    protected setCount(obj?: any) {
        let tmp = obj;
        if (tmp === undefined) {
            tmp = {};
        }
        if (!tmp.count) {
            tmp['count'] = 20;
        }

        return tmp;
    }

    protected isValidJSON(src) {
        let filtered = src;
        filtered = filtered.replace(/\\["\\\/bfnrtu]/g, '@');
        filtered = filtered.replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            ']'
        );
        filtered = filtered.replace(/(?:^|:|,)(?:\s*\[)+/g, '');

        return /^[\],:{}\s]*$/.test(filtered);
    }

    /**
     * * Собирает параметры с url
     *
     * Количество по умолчанию (все 500)
     *
     * @param obj
     * @param isParamsGet
     * @param like
     * @param deletePagin
     */
    protected setParamsUrl(
        obj?: any,
        isParamsGet: boolean = true,
        like: string[] = [],
        deletePagin: boolean = false
    ) {
        const params: any = {};
        const config = this._config.get('site')['def'];
        let tmp: any = {};
        /**
         * Убираем пустые значение
         */
        if (isParamsGet) {
            _.forEach(this._activatedRoute.snapshot.queryParams, (v, k) => {
                if (k === 'creation_date' && this.isValidJSON(v)) {
                    params[k] = JSON.parse(v);
                    return;
                }
                if (v) {
                    params[k] = v;
                }
            });
        }

        _.forEach(obj, (v, k) => {
            if (v !== '') {
                if (v === 'false') {
                    tmp[k] = false;
                } else if (v === 'true') {
                    tmp[k] = true;
                } else {
                    tmp[k] = v;
                }
            }
        });

        /**
         * Вставляем значение по умолчанию если есть пустые значение
         */
        if (tmp === undefined) {
            tmp = {};
        }

        if (!params.count) {
            if (!tmp.count) {
                tmp['count'] = config.elementCount;
            }
        } else {
            if (!tmp.count) {
                tmp.count = params.count;
            }
        }
        if (!params.page) {
            tmp['page'] = config.startPage;
        } else {
            if (!tmp.page) {
                tmp.page = params.page;
            }
        }

        if (deletePagin) {
            delete tmp.page;
            delete tmp.count;
        }

        const res = Object.assign(tmp, params);
        if (like.length > 0) {
            _.forEach(res, (v, k) => {
                if (like.indexOf(k.toString()) !== -1) {
                    res[k] = `%${v}%`;
                }
            });
        }
        return res;
    }

    downloadFile(data: any, isXlsx?: boolean) {
        let fileType = '';
        if (isXlsx) {
            fileType =
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else {
            fileType = 'application/zip';
        }
        const blob = new Blob([data], { type: fileType });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    }
}
