import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Select } from './select';
import { concat, Observable } from 'rxjs';

@Component({
    selector: 'app-input-select',
    templateUrl: 'select.component.html',
})
export class SelectComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = 'Выберите ...';
    @Input() focus: boolean = false;
    @Input() search: boolean = false;
    @Input() notFoundText: string = 'Не найдено';
    @Input() options: Select[];
    @Input() clearable: boolean = true;

    @Output() enter = new EventEmitter<boolean>();
    @Output() change = new EventEmitter<any>();

    @ViewChild('select') elemInput: ElementRef;

    public error: boolean = false;

    private concat$: Observable<any>;

    ngOnInit() {
        this.concat$ = concat(
            this.control.root.get('submit').valueChanges,
            this.control.valueChanges
        );
        this.concat$.subscribe((res) => {
            this.error = false;
            const submit = this.control.root.get('submit').value;
            if (
                (this.control.invalid && this.control.dirty && submit) ||
                (this.control.pristine && this.control.invalid && submit)
            ) {
                this.error = true;
            }
        });
    }

    ngOnDestroy() {}

    onChange() {
        this.change.emit(this.control.value);
    }
}
