import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { Routes, RouterModule } from '@angular/router';
import { Guard } from '@core/service/auth/guard';

const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [Guard],
        canActivateChild: [Guard],
        children: [
            {
                path: 'notifications',
                loadChildren: () =>
                    import('@pages/notifications/notifications.module').then(
                        (m) => m.NotificationsModule
                    ),
            },
            {
                path: 'orders',
                loadChildren: () =>
                    import('@pages/orders/orders.module').then(
                        (m) => m.OrdersModule
                    ),
            },
            {
                path: 'specials',
                loadChildren: () =>
                    import('@pages/specials/specials.module').then(
                        (m) => m.SpecialsModule
                    ),
            },
            {
                path: 'items',
                loadChildren: () =>
                    import('@pages/items/items.module').then(
                        (m) => m.ItemsModule
                    ),
            },
            {
                path: 'stores',
                loadChildren: () =>
                    import('@pages/stores/stores.module').then(
                        (m) => m.StoresModule
                    ),
            },
            {
                path: 'reports',
                loadChildren: () =>
                    import('@pages/reports/reports.module').then(
                        (m) => m.ReportsModule
                    ),
            },
            {
                path: 'content',
                loadChildren: () =>
                    import('@pages/content/content.module').then(
                        (m) => m.ContentModule
                    ),
            },
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('@pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'clients',
                loadChildren: () =>
                    import('@pages/clients/clients.routs').then(
                        (m) => m.CLIENTS_ROUTS
                    ),
            },
            {
                path: 'pharmacy-monitoring',
                loadChildren: () =>
                    import(
                        '@pages/pharmacy-monitoring/pharmacy-monitoring.module'
                    ).then((m) => m.PharmacyMonitoringModule),
            },
            {
                path: 'search',
                loadChildren: () =>
                    import('@pages/search/search.module').then(
                        (m) => m.SearchModule
                    ),
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {}
