import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class Cookie implements Storage {

    [index: number]: string;
    [key: string]: any;
    length: number;

    constructor(private cookieService: CookieService) { }

    clear(): void {
        this.cookieService.removeAll();
    }

    getItem(key: string): string {
        return this.cookieService.get(key);
    }

    key(index: number): string {
        return this.cookieService.getAll().propertyIsEnumerable[index];
    }

    removeItem(key: string): void {
        this.cookieService.remove(key);
    }

    setItem(key: string, value: string): void {
        this.cookieService.put(key, value);
    }

}
