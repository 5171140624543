import { Injectable } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';

@Injectable()
export class AbsToFromControlService {
    constructor() {}

    public convertToFormControl(
        absCtrl: AbstractControl | null
    ): UntypedFormControl {
        const ctrl = absCtrl as UntypedFormControl;
        return ctrl;
    }

    public convertToFormGroup(
        absCtrl: AbstractControl | null
    ): UntypedFormGroup {
        const ctrl = absCtrl as UntypedFormGroup;
        return ctrl;
    }

    public convertToFormArray(
        absCtrl: AbstractControl | null
    ): UntypedFormArray {
        const ctrl = absCtrl as UntypedFormArray;
        return ctrl;
    }
}
