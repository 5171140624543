<input #number
       id="{{id}}"
       type="number"
       min="{{min}}"
       max="{{max}}"
       step="{{step}}"
       placeholder="{{placeholder}}"
       class="form-control m-input"
       autocomplete="off"
       [formControl]="absToFormCtr(control)"
       [class.error]="(control.invalid && control.dirty && submit) ||
            (control.pristine && control.invalid && submit)"
       (keyup.enter)="evenEnter()"
       (input)="validate($event)" />
