import { Injectable } from '@angular/core';
import { ApiRequest } from '@core/service/api/request';
import * as Interface from './../../interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from '@core/service/api/utils';

@Injectable({
    providedIn: 'any',
})
export class ApiAuthLogoutService extends ApiUtilsService {
    constructor(
        protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config
    ) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Сброс авторизации
     *
     * @return {Promise}
     */
    put() {
        return this._request.put('ma/arm/v1/auth/logout');
    }
}
