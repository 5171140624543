import { Injectable } from '@angular/core';

@Injectable()
export class Session {

    constructor() {
    }

    /**
     * Запись параметра
     *
     * @param {string} name
     * @param {string} value
     */
    public set(name: string, value: string) {
        if (value) {
            sessionStorage[name] = value;
        } else {
            this.remove(name);
        }
    }

    /**
     * Возращает параметр
     *
     * @param {string} name
     * @returns {any}
     */
    public get(name: string) {
        return sessionStorage[name];
    }

    /**
     * Удаляет параметр
     *
     * @param {string} name
     */
    public remove(name: string) {
        delete sessionStorage[name];
    }
}
