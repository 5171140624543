<div class="input-group m-input-group">
    <ng-select
            #select_find_multiple_add
            [items]="options"
            style="width: 100%;"
            placeholder="{{placeholder}}"
            bindLabel="name"
            bindValue="id"
            [multiple]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            [hideSelected]="true"
            [clearable]="clearable"
            [(ngModel)]="selected"
            [searchable]="search"
            notFoundText="{{notFoundText}}"
            (change)="onChange($event)"
            (search)="onSearch($event)"
            [addTag]="addTag"
            [addTagText]="addTagText"
            [loading]="loading"
    >
    </ng-select>
</div>
