import { Injectable } from '@angular/core';
import { Cookie } from '../cookie';
import { BehaviorSubject } from 'rxjs';
import { Permissions } from '@core/service/api/methods/auth-check/interface';
import { Router } from '@angular/router';
import { ApiAuthLogoutService } from '../api/methods/auth-logout';
import { ApiAuthLoginService } from '../api/methods/auth-login';
import { ApiAuthCheckService } from '../api/methods/auth-check';

@Injectable({
    providedIn: 'root',
})
export class Auth {
    private nameAuthToken = 'auth_token';
    public operatorLogin: BehaviorSubject<string | null> = new BehaviorSubject<
        string | null
    >(null);

    public resultCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    public permission: BehaviorSubject<Permissions> = new BehaviorSubject(null);

    constructor(
        private _apiAuthLoginService: ApiAuthLoginService,
        private _apiAuthLogoutService: ApiAuthLogoutService,
        private _apiAuthCheckService: ApiAuthCheckService,
        private _cookie: Cookie,
        private _router: Router
    ) {}

    /**
     * Получение токена
     *
     * @param {string} login
     * @param {string} password
     *
     * @returns {Promise<boolean>}
     */
    login(login: string, password: string) {
        const obj = {
            login: login,
            password: password,
        };

        return this._apiAuthLoginService.put(obj).then((res: any) => {
            this.setToken(res[this.nameAuthToken]);
            this.check();
            this.operatorLogin.next(obj.login);
            return true;
        });
    }

    /**
     * Выход
     *
     * @returns {Promise<boolean>}
     **/
    logout() {
        return this._apiAuthLogoutService
            .put()
            .then((res: any) => {
                this.operatorLogin.next(null);
                return this.check().then(() => {
                    return true;
                });
            })
            .catch(() => {
                return false;
            });
    }

    /**
     * Запись auth token
     *
     * @param {string} token
     */
    setToken(token: string): void {
        if (token.length === 64) {
            this._cookie.setItem(this.nameAuthToken, token);
        }
    }

    /**
     * Возращает токен
     *
     * @returns {string}
     */
    getToken(): string {
        return <string>this._cookie.getItem(this.nameAuthToken);
    }

    /**
     * Проверка токена
     * @returns {Promise<OutPost>}
     */
    check(): Promise<any> {
        if (this.getToken()) {
            return this._apiAuthCheckService
                .post()
                .then((res) => {
                    const result = res.result;
                    this.operatorLogin.next(res.login);
                    this.resultCheck.next(result);
                    this.permission.next(res.permissions);
                    if (result === false) {
                        this._cookie.removeItem(this.nameAuthToken);
                    }

                    return result;
                })
                .catch((err: any) => {
                    this._cookie.removeItem(this.nameAuthToken);
                    this.resultCheck.next(false);
                    return false;
                });
        }

        return new Promise((resolve) => {
            resolve(false);
        });
    }

    checkUrlPath(url: string) {
        const arrUrl = url.replace(/\?.*/, '').split('/').slice(1);
        const permission = this.permission.getValue();
        if (permission) {
            if (arrUrl[0] !== 'dashboard') {
                if (permission[arrUrl[0]]) {
                    if (permission[arrUrl[0]].indexOf(arrUrl[1]) !== -1) {
                        return true;
                    } else {
                        this._router.navigate(['/']);
                        return false;
                    }
                }
            } else {
                return true;
            }
        }
    }
}
