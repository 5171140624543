import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment-mini-ts';
import { Config } from '@core/service/config';

@Component({
    selector: 'app-input-date',
    templateUrl: 'date.component.html',
})
export class DateComponent implements OnInit, AfterViewInit {
    private _config = inject(Config);

    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() disabled: boolean = false;
    @Input() inUtc: boolean = false;

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('date') elemInput: ElementRef;

    public value: string = '06.12.2018';

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }

        this.value = this.formDateUp(this.control.value);
    }

    formDateUp(_timestamp: number): string {
        if (_timestamp) {
            const configSite = this._config.get('site');
            return moment.unix(_timestamp).format(<string>configSite.date.date);
        }
        return '';
    }

    evenEnter() {
        this.enter.emit(true);
    }

    ngAfterViewInit() {
        const configSite = this._config.get('site');

        $.fn.datepicker['dates']['ru'] = {
            days: [
                'воскресенье',
                'понедельник',
                'вторник',
                'среда',
                'четверг',
                'пятница',
                'суббота',
            ],
            daysShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
            daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            months: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь',
            ],
            monthsShort: [
                'Янв',
                'Фев',
                'Мар',
                'Апр',
                'Май',
                'Июн',
                'Июл',
                'Авг',
                'Сен',
                'Окт',
                'Ноя',
                'Дек',
            ],
            today: 'Сегодня',
            clear: 'Очистить',
            format: <string>configSite.date.date.toLowerCase(),
            titleFormat: 'MM yyyy',
            weekStart: 1,
        };

        let selector = '.m_datepicker';
        if (this.id.length > 0) {
            selector = `#${this.id} .m_datepicker`;
        }
        const datepicker = $(selector);
        datepicker
            .datepicker({
                todayHighlight: true,
                language: 'ru',
                orientation: 'bottom left',
                templates: {
                    leftArrow: '<i class="la la-angle-left"></i>',
                    rightArrow: '<i class="la la-angle-right"></i>',
                },
            })
            .on('changeDate', (e: any) => {
                const date = moment(
                    e.format(0, configSite.date.date.toLowerCase()),
                    configSite.date.date
                );
                let shift = 0;
                if (this.inUtc) {
                    shift = date.utcOffset() * 60;
                }
                this.control.setValue(date.utc().unix() + shift);
            });
    }
}
