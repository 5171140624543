<input #text
       id="{{id}}"
       type="text"
       placeholder="{{placeholder}}"
       class="form-control m-input"
       autocomplete="off"
       [attr.disabled]="disabledParsed"
       [formControl]="absToFormCtr(control)"
       [class.error]="(control.invalid && control.dirty && submit) ||
            (control.pristine && control.invalid && submit)"
       (keyup.enter)="evenEnter()" />
