import { Component, Input, OnInit } from '@angular/core';
import { concat, from, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
} from 'rxjs/operators';
import { List } from '@core/service/api/methods/stores/interface';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ApiRequest } from '@core/service/api/request';

/**
 * @deprecated Замена select-autocomplete
 */
@Component({
    selector: 'search-autocomplete',
    templateUrl: './select-find-autocomplete.component.html',
})
export class SelectFindAutocompleteComponent implements OnInit {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() options: {};
    @Input() prepareFunc: Function;
    @Input() searchField: string;
    @Input() url: string;
    @Input() placeholder: string = '';
    items$: Observable<List[]>;
    list: any;
    public selected;
    loading = false;
    itemInput$ = new Subject<string>();

    constructor(protected _request: ApiRequest) {}

    ngOnInit() {
        this.loadItems();

        if (this.control.value) {
            this.selected = this.control.value;
            this.control.setValue(this.control.value['id']);
        }
    }

    private loadItems() {
        this.items$ = concat(
            of([]), // default items
            this.itemInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.loading = true)),
                switchMap((term) => {
                    const option = {
                        ...this.options,
                        ...{ count: 500 },
                    };
                    option[this.searchField] = term;
                    return from(
                        this.get(option).then((res) => {
                            this.prepareFunc(res.list);
                            return res.list;
                        })
                    ).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.loading = false))
                    );
                })
            )
        );
    }

    get(obj?: any): Promise<any> {
        return this._request
            .get(this.url, obj)
            .then((res: Response) => res.json);
    }

    onChange(event) {
        this.selected = event;
        this.control.setValue(event.id);
    }
}
