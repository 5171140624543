<div class="app-upload-image">
    <app-loading-states [loading]="loading"></app-loading-states>
    <div class="app-upload-image__image" *ngIf="url">
        <img [src]="url" >
    </div>
    <div>
        <button class="btn btn-secondary btn-sm" type="button" (click)="input_file.click()">
            <ng-container *ngIf="!url">
                Загрузить
            </ng-container>
            <ng-container *ngIf="url">
                Обновить
            </ng-container>
        </button>
        <!--<button class="btn btn-danger btn-sm" type="button" (click)="onClickDelete()">-->
            <!--<i class="fa fa-remove"></i>-->
        <!--</button>-->
        <input #input_file class="app-upload-image__file" type='file' (change)="readUrl($event)">
    </div>
</div>

