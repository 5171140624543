<div class="m-dropdown__body">
    <div class="m-dropdown__content">
        <ul class="m-nav m-nav--skin-light">
            <li class="m-nav__separator m-nav__separator--fit"></li>
            <li class="m-nav__item">
                <a (click)="onClick()" class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">
                    Выход
                </a>
            </li>
        </ul>
    </div>
</div>