import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-input-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.css'],
})
export class InpitImageComponent implements OnInit {
    @Input() control: UntypedFormControl | AbstractControl;
    @Output() onChange = new EventEmitter<any>();

    public loading: boolean = false;
    public url: string = '';

    constructor() { }

    ngOnInit() {
        if (this.control.value && typeof this.control.value === 'string') {
            this.url = this.control.value;
        }

        if (typeof this.control === 'string') {
            this.url = this.control;
        }

        this.control.valueChanges.subscribe((res) => {
            this.url = res;
        });
    }

    readUrl(event: any) {
        if (event.target.files && event.target.files[0]) {
            this.control.setValue(event.target.files[0]);
            this.loading = true;
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.url = e.target.result;
                this.loading = false;
            };

            reader.readAsDataURL(event.target.files[0]);
            this.onChange.emit(event.target.files[0]);
        }
    }

    onClickDelete() {
        this.url = '';
        this.onChange.emit('');
    }
}
