export const environment = {
    production: process.env.NG_APP_IS_PRODUCTION !== 'false',
    api: {
        url: process.env.NG_APP_API_URL,
        port: '',
        protocol: 'https',
        secret: process.env.NG_APP_API_SECRET,
        ncp: true,
        header: {
            'Content-Type': 'application/json',
            platform: 4,
        },
    },
    debug: false,
    baseUrls: {
        api: process.env.NG_APP_BASE_URL_API_URL,
        arm: process.env.NG_APP_BASE_URL_ARM_URL,
        site: process.env.NG_APP_BASE_URL_SITE_URL,
    },
    site: {
        date: {
            full: 'DD.MM.YYYY HH:mm',
            time: 'HH:mm',
            date: 'DD.MM.YYYY',
        },
        def: {
            elementCount: 25,
            startPage: 1,
            autoCompleteCount: 10,
            perPageList: [5, 15, 25],
            showPageCount: 7,
        },
        updateTime: 5, // Интервал обновления данных, в секундах
        checkAuthTime: 5, // Интервал проверки авторизации, в секундах
        logoutTime: 30, // Время бездействия после которого нужна будет авторизация, в минутах
    },
    yandex_key: process.env.NG_APP_YA_ID_MAPS,
};
