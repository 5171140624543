import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { SelectFind } from './select-find';
import { Subscription, Subject } from 'rxjs';

@Component({
    selector: 'app-input-select-find',
    templateUrl: 'select-find.component.html',
})
export class SelectFindComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() groupControl: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() options: SelectFind[];
    @Input() typeahead: Subject<string | null>;
    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Input() getParent: boolean = false;
    @Input() search: boolean = true;
    @Input() notFoundText: string = 'Не найдено';
    @Input() bindValue: string = 'id';
    @Input() bindLabel: string = 'name';
    @Input() clearable: boolean = true;

    @Output() enter = new EventEmitter<boolean>();
    @Output() change = new EventEmitter<any>();

    @ViewChild('select_find') elemInput: ElementRef;

    public value: number = 0;
    public submit: boolean = false;

    public selected;
    private submit$: Subscription;

    ngOnInit() {
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
        if (this.control.value != null) {
            this.selected = this.control.value;
        }

        this.control.valueChanges.subscribe((res) => {
            this.selected = res;
        });

        if (this.control.root.get('submit') instanceof UntypedFormControl) {
            this.submit$ = this.control.root
                .get('submit')
                .valueChanges.subscribe((res) => {
                    this.submit = res;
                });
        }
    }

    ngOnDestroy() {
        if (this.submit$) {
            this.submit$.unsubscribe();
        }
    }

    onChange(event) {
        this.control.setValue(event);
        if (this.getParent) {
            for (const item of this.options) {
                if (item.id === event) {
                    this.groupControl.setValue(item.group_id);
                }
            }
        }
        this.change.emit(this.control.value);
    }
}
