<div class='input-group m_datetimepicker_range' >
    <input type='text'
           value="{{value}}"
           class="form-control m-input"
           readonly
           placeholder="Выбор диапазона дат"/>
    <div class="input-group-append">
    <span class="input-group-text">
        <i class="la la-calendar-check-o"></i>
    </span>
    </div>
</div>