<textarea #textarea
          id="{{id}}"
          placeholder="{{placeholder}}"
          class="form-control m-input"
          autocomplete="off"
          rows="{{rows}}"
          [attr.disabled]="disabled"
          [formControl]="absToFormCtr(control)"
          [class.error]="(control.invalid && control.dirty && control.root.get('submit').value) ||
            (control.pristine && control.invalid && control.root.get('submit').value)"
          (keyup.enter)="evenEnter()"></textarea>
