import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    UntypedFormControl,
} from '@angular/forms';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-input-image-croppie',
    templateUrl: './image-croppie.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./image-croppie.component.css'],
})
export class InputImageCroppieComponent implements OnInit, OnDestroy {
    @Input() control?: UntypedFormControl | AbstractControl = new FormControl();
    @Input() srcUrl?: string;
    @Output() onChange = new EventEmitter<any>();
    @Output() onApply = new EventEmitter<boolean>();

    @Input() showApplyBtn: boolean = false;
    @Input() showEditBtn: boolean = true;

    @ViewChild('input_file') inputFile: ElementRef;

    public loading: boolean = false;
    public url: string;
    public imageChangedEvent: any = '';
    public fileInputValue: any;
    public nowEdited: boolean = false;

    public imageUrl: any;

    private controlSubs$: Subscription;

    constructor() {}

    ngOnInit(): void {
        if (
            this.control &&
            this.control.value &&
            typeof this.control.value === 'string'
        ) {
            this.url = this.control.value;
        } else if (this.srcUrl && this.srcUrl.length > 0) {
            this.url = this.srcUrl;
        }

        this.controlSubs$ = this.control.valueChanges.subscribe((res) => {
            if (res === null) {
                this.onClickDelete();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.controlSubs$) {
            this.controlSubs$.unsubscribe();
        }
    }

    public onClickDelete(): void {
        this.url = null;
        this.imageUrl = null;
        this.control.setValue(false);
        this.nowEdited = false;
        this.onChange.emit(false);
    }

    public fileChangeEvent(event: any): void {
        this.nowEdited = true;
        this.imageChangedEvent = event;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        const file = base64ToFile(event.base64);
        this.url = event.base64;
        const b = this.blobToFile(<any>file, 'image.jpg');

        this.control.setValue(b);
        this.onChange.emit(b);
    }

    public blobToFile = (theBlob: Blob, fileName: string): File => {
        return <File>new File([theBlob], fileName, {
            lastModified: Date.now(),
            type: theBlob.type,
        });
    };

    public cropperReady() {}

    public imageLoaded() {}

    public loadImageFailed() {}

    public onEdit(): void {
        this.nowEdited = true;
        const toDataURL = (url) =>
            fetch(url)
                .then((response) => response.blob())
                .then(
                    (blob) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        })
                );
        toDataURL(this.url).then((dataUrl) => {
            this.imageUrl = dataUrl.toString();
        });
    }

    public onApplyClick(): void {
        this.onApply.emit(true);
    }
}
