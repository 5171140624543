import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { AbsToFromControlService } from '@shared/services/abs-to-from-control.service';

@Component({
    selector: 'app-input-checkbox',
    templateUrl: 'checkbox.component.html',
    providers: [AbsToFromControlService],
})
export class CheckboxComponent implements OnInit {
    @Input() control: UntypedFormControl | AbstractControl;
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() disabled: boolean | null = null;
    @Input() label: string = '';

    @Output() enter = new EventEmitter<boolean>();

    @ViewChild('checkbox') elemInput: ElementRef;

    public absToFormCtr: Function;

    constructor(private _absToForm: AbsToFromControlService) {
        this.absToFormCtr = this._absToForm.convertToFormControl;
    }

    ngOnInit() {
        if (this.disabled === false) {
            this.disabled = null;
        }
        if (this.focus) {
            this.elemInput.nativeElement.focus();
        }
    }

    evenEnter() {
        this.enter.emit(true);
    }
}
