<div class="m-radio-list">
    <ng-container *ngFor="let opt of options">
        <label class="m-radio">
            <input type="radio"
                   autocomplete="off"
                   name="{{name}}"
                   [formControl]="absToFormCtr(control)"
                   [class.error]="(control.invalid && control.dirty && control.root.get('submit').value) ||
        (control.pristine && control.invalid && control.root.get('submit').value)"
                   (keyup.enter)="evenEnter()"
                   [value]="opt.value"
                   [checked]="control.value === opt.value" />{{opt.name}}<span></span>
        </label>
    </ng-container>
</div>
